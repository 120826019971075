<template>
  <div class="content">
    <Player :playlist="this.playlist" :cover="this.cover" :title="this.title" />
  </div>
</template>

<script>
import Player from "@/components/Player.vue";
export default {
  components: {
    Player,
  },
  data() {
    return {
      title: "Four Swords Adventure",
      cover: "four-swords-adventure.jpg",
      playlist: [
        {
          mp3:
            "https://static.zreoarchive.org/mp3/four-swords-adventure/Frozen-Hyrule.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/four-swords-adventure/Frozen-Hyrule.ogg",
          title: "Frozen Hyrule",
        },
      ],
    };
  },
};
</script>
