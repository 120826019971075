<template>
  <div class="content">
    <Player :playlist="this.playlist" :cover="this.cover" :title="this.title" />
  </div>
</template>

<script>
import Player from "@/components/Player.vue";
export default {
  components: {
    Player,
  },
  data() {
    return {
      title: "Phantom Hourglass",
      cover: "phantom-hourglass.jpg",
      playlist: [
        {
          mp3:
            "https://static.zreoarchive.org/mp3/phantom-hourglass/Linebeck.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/phantom-hourglass/Linebeck.ogg",
          title: "Linebeck",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/phantom-hourglass/Mercay-Island.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/phantom-hourglass/Mercay-Island.ogg",
          title: "Mercay Island",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/phantom-hourglass/Inside-a-House.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/phantom-hourglass/Inside-a-House.ogg",
          title: "Inside a House",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/phantom-hourglass/Chasing-the-Ghost-Ship.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/phantom-hourglass/Chasing-the-Ghost-Ship.ogg",
          title: "Chasing the Ghost Ship",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/phantom-hourglass/Cielas-Theme.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/phantom-hourglass/Cielas-Theme.ogg",
          title: "Ciela's Theme",
        },
      ],
    };
  },
};
</script>
