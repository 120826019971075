<template>
  <div class="content">
    <Player :playlist="this.playlist" :cover="this.cover" :title="this.title" />
  </div>
</template>

<script>
import Player from "@/components/Player.vue";
export default {
  components: {
    Player,
  },
  data() {
    return {
      title: "Open Symphony",
      cover: "open-symphony.jpg",
      playlist: [
        {
          mp3:
            "https://static.zreoarchive.org/mp3/open-symphony/Overture.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/open-symphony/Overture.ogg",
          title: "Overture",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/open-symphony/Memories-of-Kakariko.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/open-symphony/Memories-of-Kakariko.ogg",
          title: "Memories of Kakariko",
        },
      ],
    };
  },
};
</script>
