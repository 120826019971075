<template>
  <div class="content">
    <Player :playlist="this.playlist" :cover="this.cover" :title="this.title" />
  </div>
</template>

<script>
import Player from "@/components/Player.vue";
export default {
  components: {
    Player,
  },
  data() {
    return {
      title: "Soundscape Vol. 1",
      cover: "soundscape-vol-1.jpg",
      playlist: [
        {
          mp3:
            "https://static.zreoarchive.org/mp3/soundscape-vol-1/A-Link-to-the-Past-Hyrule-Castle.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/soundscape-vol-1/A-Link-to-the-Past-Hyrule-Castle.ogg",
          title: "A Link to the Past Hyrule Castle",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/soundscape-vol-1/Ocarina-of-Time-Market.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/soundscape-vol-1/Ocarina-of-Time-Market.ogg",
          title: "Ocarina of Time Market",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/soundscape-vol-1/Ocarina-of-Time-Gerudo-Valley.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/soundscape-vol-1/Ocarina-of-Time-Gerudo-Valley.ogg",
          title: "Ocarina of Time Gerudo Valley",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/soundscape-vol-1/Majoras-Mask-Jungle-Cruise.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/soundscape-vol-1/Majoras-Mask-Jungle-Cruise.ogg",
          title: "Majora's Mask Jungle Cruise",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/soundscape-vol-1/The-Wind-Waker-Outset-Island.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/soundscape-vol-1/The-Wind-Waker-Outset-Island.ogg",
          title: "The Wind Waker Outset Island",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/soundscape-vol-1/The-Wind-Waker-Inside-the-Pirate-Ship.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/soundscape-vol-1/The-Wind-Waker-Inside-the-Pirate-Ship.ogg",
          title: "The Wind Waker Inside the Pirate Ship",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/soundscape-vol-1/Twilight-Princess-Lake-Hylia.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/soundscape-vol-1/Twilight-Princess-Lake-Hylia.ogg",
          title: "Twilight Princess Lake Hylia",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/soundscape-vol-1/A-Link-to-the-Past-Sacred-Grove.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/soundscape-vol-1/A-Link-to-the-Past-Sacred-Grove.ogg",
          title: "A Link to the Past Sacred Grove",
        },
      ],
    };
  },
};
</script>
