<template>
  <div class="content">
    <Player :playlist="this.playlist" :cover="this.cover" :title="this.title" />
  </div>
</template>

<script>
import Player from "@/components/Player.vue";
export default {
  components: {
    Player,
  },
  data() {
    return {
      title: "Soundscape Vol. 3",
      cover: "soundscape-vol-3.jpg",
      playlist: [
        {
          mp3:
            "https://static.zreoarchive.org/mp3/soundscape-vol-3/Twilight-Princess-Enter-Ilia.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/soundscape-vol-3/Twilight-Princess-Enter-Ilia.ogg",
          title: "Twilight Princess Enter Ilia",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/soundscape-vol-3/Ocarina-of-Time-Kokiri-Forest.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/soundscape-vol-3/Ocarina-of-Time-Kokiri-Forest.ogg",
          title: "Ocarina of Time Kokiri Forest",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/soundscape-vol-3/Ocarina-of-Time-Great-Fairys-Fountain.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/soundscape-vol-3/Ocarina-of-Time-Great-Fairys-Fountain.ogg",
          title: "Ocarina of Time Great Fairy's Fountain",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/soundscape-vol-3/The-Wind-Waker-Ocean.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/soundscape-vol-3/The-Wind-Waker-Ocean.ogg",
          title: "The Wind Waker Ocean",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/soundscape-vol-3/Ocarina-of-Time-Zoras-Domain.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/soundscape-vol-3/Ocarina-of-Time-Zoras-Domain.ogg",
          title: "Ocarina of Time Zora's Domain",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/soundscape-vol-3/The-Wind-Waker-Windfall.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/soundscape-vol-3/The-Wind-Waker-Windfall.ogg",
          title: "The Wind Waker Windfall",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/soundscape-vol-3/Majoras-Mask-Pirates-Fortress.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/soundscape-vol-3/Majoras-Mask-Pirates-Fortress.ogg",
          title: "Majora's Mask Pirates' Fortress",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/soundscape-vol-3/A-Link-to-the-Past-Rain-Scene.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/soundscape-vol-3/A-Link-to-the-Past-Rain-Scene.ogg",
          title: "A Link to the Past Rain Scene",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/soundscape-vol-3/The-Wind-Waker-Farewell-Hyrule-King.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/soundscape-vol-3/The-Wind-Waker-Farewell-Hyrule-King.ogg",
          title: "The Wind Waker Farewell Hyrule King",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/soundscape-vol-3/Ocarina-of-Time-Last-Battle.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/soundscape-vol-3/Ocarina-of-Time-Last-Battle.ogg",
          title: "Ocarina of Time Last Battle",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/soundscape-vol-3/The-Wind-Waker-Outset-Island-Redux.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/soundscape-vol-3/The-Wind-Waker-Outset-Island-Redux.ogg",
          title: "The Wind Waker Outset Island Redux",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/soundscape-vol-3/Majoras-Mask-Ikana-Valley.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/soundscape-vol-3/Majoras-Mask-Ikana-Valley.ogg",
          title: "Majora's Mask Ikana Valley",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/soundscape-vol-3/The-Wind-Waker-Staff-Credits-Redux.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/soundscape-vol-3/The-Wind-Waker-Staff-Credits-Redux.ogg",
          title: "The Wind Waker Staff Credits Redux",
        },
      ],
    };
  },
};
</script>
