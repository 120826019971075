<template>
  <div class="content">
    <Player :playlist="this.playlist" :cover="this.cover" :title="this.title" />
  </div>
</template>

<script>
import Player from "@/components/Player.vue";
export default {
  components: {
    Player,
  },
  data() {
    return {
      title: "A Link to the Past",
      cover: "link-to-the-past.jpg",
      playlist: [
        {
          mp3:
            "https://static.zreoarchive.org/mp3/link-to-the-past/Intro-Theme.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/link-to-the-past/Intro-Theme.ogg",
          title: "Intro Theme",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/link-to-the-past/Opening-Demo.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/link-to-the-past/Opening-Demo.ogg",
          title: "Opening Demo",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/link-to-the-past/Rain-Scene.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/link-to-the-past/Rain-Scene.ogg",
          title: "Rain Scene",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/link-to-the-past/Hyrule-Castle.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/link-to-the-past/Hyrule-Castle.ogg",
          title: "Hyrule Castle",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/link-to-the-past/Zeldas-Rescue.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/link-to-the-past/Zeldas-Rescue.ogg",
          title: "Zelda's Rescue",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/link-to-the-past/Sanctuary.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/link-to-the-past/Sanctuary.ogg",
          title: "Sanctuary",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/link-to-the-past/Light-World-Overworld.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/link-to-the-past/Light-World-Overworld.ogg",
          title: "Light World Overworld",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/link-to-the-past/Kakariko-Village.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/link-to-the-past/Kakariko-Village.ogg",
          title: "Kakariko Village",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/link-to-the-past/Cave.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/link-to-the-past/Cave.ogg",
          title: "Cave",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/link-to-the-past/Light-World-Dungeon.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/link-to-the-past/Light-World-Dungeon.ogg",
          title: "Light World Dungeon",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/link-to-the-past/Boss-Battle.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/link-to-the-past/Boss-Battle.ogg",
          title: "Boss Battle",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/link-to-the-past/Lost-Woods.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/link-to-the-past/Lost-Woods.ogg",
          title: "Lost Woods",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/link-to-the-past/Dark-World-Overworld.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/link-to-the-past/Dark-World-Overworld.ogg",
          title: "Dark World Overworld",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/link-to-the-past/Dark-World-Dungeon.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/link-to-the-past/Dark-World-Dungeon.ogg",
          title: "Dark World Dungeon",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/link-to-the-past/Ending-Theme.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/link-to-the-past/Ending-Theme.ogg",
          title: "Ending Theme",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/link-to-the-past/End-Credits.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/link-to-the-past/End-Credits.ogg",
          title: "End Credits",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/link-to-the-past/Light-World-Overworld-Redux.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/link-to-the-past/Light-World-Overworld-Redux.ogg",
          title: "Light World Overworld Redux",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/link-to-the-past/Ending-Theme-Redux.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/link-to-the-past/Ending-Theme-Redux.ogg",
          title: "Ending Theme Redux",
        },
      ],
    };
  },
};
</script>
