<template>
  <div class="content">
    <Player :playlist="this.playlist" :cover="this.cover" :title="this.title" />
  </div>
</template>

<script>
import Player from "@/components/Player.vue";
export default {
  components: {
    Player,
  },
  data() {
    return {
      title: "Oracle of Ages",
      cover: "oracle-of-ages.jpg",
      playlist: [
        {
          mp3: "https://static.zreoarchive.org/mp3/oracle-of-ages/Title-Theme.mp3",
          ogg: "https://static.zreoarchive.org/ogg/oracle-of-ages/Title-Theme.ogg",
          title: "Title Theme",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/oracle-of-ages/Nayrus-Song.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/oracle-of-ages/Nayrus-Song.ogg",
          title: "Nayru's Song",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/oracle-of-ages/Syrup-the-Witch.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/oracle-of-ages/Syrup-the-Witch.ogg",
          title: "Syrup the Witch",
        },
      ],
    };
  },
};
</script>
