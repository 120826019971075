<template>
  <div class="content">
    <Player :playlist="this.playlist" :cover="this.cover" :title="this.title" />
  </div>
</template>

<script>
import Player from "@/components/Player.vue";
export default {
  components: {
    Player,
  },
  data() {
    return {
      title: "Soundscape Vol. 2",
      cover: "soundscape-vol-2.jpg",
      playlist: [
        {
          mp3:
            "https://static.zreoarchive.org/mp3/soundscape-vol-2/Majoras-Mask-Clock-Town-Day-1.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/soundscape-vol-2/Majoras-Mask-Clock-Town-Day-1.ogg",
          title: "Majora's Mask Clock Town Day 1",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/soundscape-vol-2/Majoras-Mask-Marsh-Land.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/soundscape-vol-2/Majoras-Mask-Marsh-Land.ogg",
          title: "Majora's Mask Marsh Land",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/soundscape-vol-2/A-Link-to-the-Past-Cave.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/soundscape-vol-2/A-Link-to-the-Past-Cave.ogg",
          title: "A Link to the Past Cave",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/soundscape-vol-2/The-Minish-Cap-Royal-Crypt.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/soundscape-vol-2/The-Minish-Cap-Royal-Crypt.ogg",
          title: "The Minish Cap Royal Crypt",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/soundscape-vol-2/Ocarina-of-Time-Lon-Lon-Ranch.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/soundscape-vol-2/Ocarina-of-Time-Lon-Lon-Ranch.ogg",
          title: "Ocarina of Time Lon Lon Ranch",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/soundscape-vol-2/Ocarina-of-Time-Hyrule-Castle-Courtyard.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/soundscape-vol-2/Ocarina-of-Time-Hyrule-Castle-Courtyard.ogg",
          title: "Ocarina of Time Hyrule Castle Courtyard",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/soundscape-vol-2/Twilight-Princess-Faron-Woods.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/soundscape-vol-2/Twilight-Princess-Faron-Woods.ogg",
          title: "Twilight Princess Faron Woods",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/soundscape-vol-2/Twilight-Princess-Fishing-Hole.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/soundscape-vol-2/Twilight-Princess-Fishing-Hole.ogg",
          title: "Twilight Princess Fishing Hole",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/soundscape-vol-2/The-Wind-Waker-Inside-the-Forest-Haven.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/soundscape-vol-2/The-Wind-Waker-Inside-the-Forest-Haven.ogg",
          title: "The Wind Waker Inside the Forest Haven",
        },
      ],
    };
  },
};
</script>
