<template>
  <div class="content">
    <Player :playlist="this.playlist" :cover="this.cover" :title="this.title" />
  </div>
</template>

<script>
import Player from "@/components/Player.vue";
export default {
  components: {
    Player,
  },
  data() {
    return {
      title: "Wink Waker",
      cover: "wind-waker.jpg",
      playlist: [
        {
          mp3: "https://static.zreoarchive.org/mp3/wind-waker/Title.mp3",
          ogg: "https://static.zreoarchive.org/ogg/wind-waker/Title.ogg",
          title: "Title",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/wind-waker/Menu-Selection.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/wind-waker/Menu-Selection.ogg",
          title: "Menu Selection",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/wind-waker/The-Legendary-Hero.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/wind-waker/The-Legendary-Hero.ogg",
          title: "The Legendary Hero",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/wind-waker/Outset-Island.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/wind-waker/Outset-Island.ogg",
          title: "Outset Island",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/wind-waker/Inside-a-House.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/wind-waker/Inside-a-House.ogg",
          title: "Inside a House",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/wind-waker/Orcas-House.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/wind-waker/Orcas-House.ogg",
          title: "Orca's House",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/wind-waker/Fencing-Instruction.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/wind-waker/Fencing-Instruction.ogg",
          title: "Fencing Instruction",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/wind-waker/Beedles-Shop.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/wind-waker/Beedles-Shop.ogg",
          title: "Beedle's Shop",
        },
        {
          mp3: "https://static.zreoarchive.org/mp3/wind-waker/Graaandma.mp3",
          ogg: "https://static.zreoarchive.org/ogg/wind-waker/Graaandma.ogg",
          title: "Graaandma",
        },
        {
          mp3: "https://static.zreoarchive.org/mp3/wind-waker/Departure.mp3",
          ogg: "https://static.zreoarchive.org/ogg/wind-waker/Departure.ogg",
          title: "Departure",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/wind-waker/Inside-the-Pirate-Ship.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/wind-waker/Inside-the-Pirate-Ship.ogg",
          title: "Inside the Pirate Ship",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/wind-waker/Forsaken-Fortress.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/wind-waker/Forsaken-Fortress.ogg",
          title: "Forsaken Fortress",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/wind-waker/Windfall-Island.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/wind-waker/Windfall-Island.ogg",
          title: "Windfall Island",
        },
        {
          mp3: "https://static.zreoarchive.org/mp3/wind-waker/Dawn.mp3",
          ogg: "https://static.zreoarchive.org/ogg/wind-waker/Dawn.ogg",
          title: "Dawn",
        },
        {
          mp3: "https://static.zreoarchive.org/mp3/wind-waker/Ocean.mp3",
          ogg: "https://static.zreoarchive.org/ogg/wind-waker/Ocean.ogg",
          title: "Ocean",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/wind-waker/Dragon-Roost-Island.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/wind-waker/Dragon-Roost-Island.ogg",
          title: "Dragon Roost Island",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/wind-waker/Forest-Haven.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/wind-waker/Forest-Haven.ogg",
          title: "Forest Haven",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/wind-waker/Inside-Forest-Haven.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/wind-waker/Inside-Forest-Haven.ogg",
          title: "Inside Forest Haven",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/wind-waker/The-Great-Sea-Is-Cursed.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/wind-waker/The-Great-Sea-Is-Cursed.ogg",
          title: "The Great Sea Is Cursed",
        },
        {
          mp3: "https://static.zreoarchive.org/mp3/wind-waker/Jabun.mp3",
          ogg: "https://static.zreoarchive.org/ogg/wind-waker/Jabun.ogg",
          title: "Jabun",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/wind-waker/Tower-of-the-Gods-Appears.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/wind-waker/Tower-of-the-Gods-Appears.ogg",
          title: "Tower of the Gods Appears",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/wind-waker/Tower-of-the-Gods.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/wind-waker/Tower-of-the-Gods.ogg",
          title: "Tower of the Gods",
        },
        {
          mp3: "https://static.zreoarchive.org/mp3/wind-waker/To-Hyrule.mp3",
          ogg: "https://static.zreoarchive.org/ogg/wind-waker/To-Hyrule.ogg",
          title: "To Hyrule",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/wind-waker/Hyrule-Castle.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/wind-waker/Hyrule-Castle.ogg",
          title: "Hyrule Castle",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/wind-waker/Hyrule-King-Appears.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/wind-waker/Hyrule-King-Appears.ogg",
          title: "Hyrule King Appears",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/wind-waker/Zeldas-Awakening.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/wind-waker/Zeldas-Awakening.ogg",
          title: "Zelda's Awakening",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/wind-waker/Ballad-of-Gales-Baton.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/wind-waker/Ballad-of-Gales-Baton.ogg",
          title: "Ballad of Gales Baton",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/wind-waker/Ballad-of-Gales.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/wind-waker/Ballad-of-Gales.ogg",
          title: "Ballad of Gales",
        },
        {
          mp3: "https://static.zreoarchive.org/mp3/wind-waker/Molgera.mp3",
          ogg: "https://static.zreoarchive.org/ogg/wind-waker/Molgera.ogg",
          title: "Molgera",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/wind-waker/Hero-of-the-Wind.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/wind-waker/Hero-of-the-Wind.ogg",
          title: "Hero of the Wind",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/wind-waker/Ganondorf-Battle.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/wind-waker/Ganondorf-Battle.ogg",
          title: "Ganondorf Battle",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/wind-waker/Farewell-Hyrule-King.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/wind-waker/Farewell-Hyrule-King.ogg",
          title: "Farewell Hyrule King",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/wind-waker/Staff-Credits.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/wind-waker/Staff-Credits.ogg",
          title: "Staff Credits",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/wind-waker/Title-Redux.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/wind-waker/Title-Redux.ogg",
          title: "Title Redux",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/wind-waker/The-Legendary-Hero-Redux.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/wind-waker/The-Legendary-Hero-Redux.ogg",
          title: "The Legendary Hero Redux",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/wind-waker/Outset-Island-Redux.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/wind-waker/Outset-Island-Redux.ogg",
          title: "Outset Island Redux",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/wind-waker/Windfall-Island-Redux.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/wind-waker/Windfall-Island-Redux.ogg",
          title: "Windfall Island Redux",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/wind-waker/Ocean-Redux.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/wind-waker/Ocean-Redux.ogg",
          title: "Ocean Redux",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/wind-waker/Ocean-Redux-v2.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/wind-waker/Ocean-Redux-v2.ogg",
          title: "Ocean Redux v2",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/wind-waker/Dragon-Roost-Island-Redux.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/wind-waker/Dragon-Roost-Island-Redux.ogg",
          title: "Dragon Roost Island Redux",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/wind-waker/Staff-Credits-Redux.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/wind-waker/Staff-Credits-Redux.ogg",
          title: "Staff Credits Redux",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/wind-waker/Staff-Credits-Redux-Soundscape.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/wind-waker/Staff-Credits-Redux-Soundscape.ogg",
          title: "Staff Credits Redux Soundscape",
        },
      ],
    };
  },
};
</script>
