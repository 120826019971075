"use strict";
export default class player {

    // Maybe I should make a pause button do a pause function.
    // Then I can make it do a full stop to the music, and not just
    // try and assume what needs to be done.

    static init(album) {

        // Used in determining if we are still on the page.
        this.album = album;

        // Used to properly handle page changes.
        if (this.audio && !this.audio.paused) {

            document.getElementById("song-title").innerHTML = this.playlist.songs[this.songid].title + " - " + this.playlist.album;

            // Maybe Use Title of Album as Key?!
            this.sondid = -1;
            this.isPlaying = true;

            // Set the page's default state.
            this.pauseButton = false;

            // Toggle button
            this.toggleButton();

        }

        // Handles page swaps between playing.
        if(this.playlist && this.album == this.playlist.album){

            this.currentlyPlaying(this.playlist.songid);

        }

        if(this.volume === undefined){

            this.volume = 0.5;
            
        }


        // Seek Watcher.
        document.getElementById("progress-amount").addEventListener('mouseup', this.seekSong.bind(this));
        document.getElementById("volume-amount").addEventListener('mouseup', this.setVolume.bind(this));
        document.getElementById("volume-amount").value = (this.volume * 100);

        // Prevent adjustment of audio when sliding.
        document.getElementById("progress-amount").addEventListener('mousedown', function(){

            this.seeking = true;

        }.bind(this));
        

    }

    static setVolume(){

        var volume = document.getElementById("volume-amount").value;

        this.audio.volume = (volume / 100);

        this.volume = (volume / 100);

    }


    // Add music to our playlist.
    static addMusic(songs) {

        if (this.playlist && this.album != this.playlist.album) {

            // Delete the tracking info.
            delete this.songid;

            // If the audio is paused. Delete it with prejucide so we don't start over.
            if (this.audio.paused) {

                // Delete the audio element, so we cleanly restart.
                delete this.audio;

            }


        }

        // Our playlist
        this.playlist = {

            album: this.album,
            songs: songs,

        }

    }

    // Pause the Music.
    static pauseSong() {

        // Pause the Song.
        this.audio.pause();
        this.isPlaying = false;

        // UI tracking if we are playing songs or not.
        this.currentlyPlaying(this.songid);
        this.toggleButton();

    }

    // Set the currently playing song as golden text.
    static currentlyPlaying(id) {

        // Only exec if we are on the right page.
        if (this.album == this.playlist.album) {

            // Reset all other items first.
            for (let i = 0; i < this.playlist.songs.length; i++) {

                document.getElementById("playitem_" + i).style.color = "";

            }

            // Set the golden text.
            document.getElementById("playitem_" + id).style.color = "#d4ce46";

        }

    }

    // Go to the next song.
    static nextSong() {

        // Seek the song forward
        let id = this.songid + 1;
        let songCount = this.playlist.songs.length - 1;

        // Verify that we haven't gone beyond the playlist length.
        if (id > songCount) {
            id = 0;
        }

        // Play the damn song already.
        return this.playSong(id);

    }

    // Go to the previous song.
    static prevSong() {

        // Seek the song back one track.
        let id = this.songid - 1;
        let songCount = this.playlist.songs.length - 1;

        // Make sure we haven't gone beyond the playlist length.
        if (id < 0) {
            id = songCount;
        }

        // Play the damn song already.
        return this.playSong(id);

    }

    // TODO: Handle page changes nicely
    static playSong(id) {

        // If we use just the play / pause button. It returns undefined.
        if (id === undefined) {

            // Assume the first track in the list.
            id = 0;

            // Use the current songid if one is present.
            // Used for pausing already playing music.
            if (this.songid) {

                id = this.songid;

            }

        }

        // See if the track has changed since the last one we played.
        if (id != this.songid) {

            // Stop currently playing song first.
            if (this.audio) {

                this.audio.pause();
                this.isPlaying = false;

            }

            // Recreate / create a new song element for the new song.
            this.audio = document.createElement('audio');

            // Assume OGG for all.
            this.audio.setAttribute('src', this.playlist.songs[id].ogg);

            // Check to see if our browser supports MP3, and then change to using it.
            if (this.audio.canPlayType("audio/mpeg")) {

                this.audio.setAttribute('src', this.playlist.songs[id].mp3);

            }

            this.audio.volume = this.volume;

            document.getElementById("song-title").innerHTML = this.playlist.songs[id].title + " - " + this.playlist.album;

            // Set our songid for tracking purposes.
            this.songid = id;
            this.playlist.songid = id;

        }

        // https://developer.mozilla.org/en-US/docs/Web/Guide/Audio_and_video_delivery/buffering_seeking_time_ranges
        this.audio.addEventListener('progress', function () {
            var duration = this.audio.duration;

            if (duration > 0) {

                for (var i = 0; i < this.audio.buffered.length; i++) {

                    if (this.audio.buffered.start(this.audio.length - 1 - i) < this.audio.currentTime) {
                        document.getElementById("buffered-amount").style.width = (this.audio.buffered.end(this.audio.length - 1 - i) / duration) * 100 + "%";
                        break;
                    }

                }
            }

        }.bind(this));

        // https://developer.mozilla.org/en-US/docs/Web/Guide/Audio_and_video_delivery/buffering_seeking_time_ranges
        this.audio.addEventListener('timeupdate', function () {
            var duration = this.audio.duration;

            if (duration > 0 && !this.seeking) {

                // This sets our current running time. 
                document.getElementById("current-time").innerHTML = this.niceTime(this.audio.currentTime);
                document.getElementById("total-time").innerHTML = this.niceTime(this.audio.duration);

                // This sets our progress bar.
                for (var i = 0; i < this.audio.buffered.length; i++) {
                    if (this.audio.buffered.start(this.audio.length - 1 - i) < this.audio.currentTime) {
                        document.getElementById("progress-amount").value = ((this.audio.currentTime / duration) * 100);
                        break;
                    }
                }

            }

        }.bind(this));

        // Check to see if the audio is playing.
        if (this.isPlaying) {

            // Stop the song.
            this.audio.pause();
            this.isPlaying = false;

            // Track for when the song ends, so we can start playing the next song.
            this.audio.removeEventListener('ended', this.nextSong.bind(this));
            
            console.log("Pausing: " + this.playlist.songs[id].title);

        }

        else {

            // Start playing the song.
            this.audio.play();
            this.isPlaying = true;

            // Track for when the song ends, so we can start playing the next song.
            this.audio.addEventListener('ended', this.nextSong.bind(this));

            console.log("Playing: " + this.playlist.songs[id].title);

        }

        // UI tracking if we are playing songs or not.
        this.currentlyPlaying(this.songid);
        this.toggleButton();

        // Return the audio element, not really used anywhere.
        return this.audio;

    }

    static seekSong(){

        var seek = document.getElementById("progress-amount").value;
        var duration = this.audio.duration;
        this.audio.currentTime = (duration / 100 * seek);

        this.seeking = false;

    }

    // https://stackoverflow.com/questions/3733227/javascript-seconds-to-minutes-and-seconds
    static niceTime(duration) {

        var mins = ~~((duration % 3600) / 60);
        var secs = ~~duration % 60;

        var ret = "";

        ret += "" + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;
        return ret;

    }

    // Swap the Play and Pause buttons depeneding on the status.
    static toggleButton() {

        // Get our play and pause button elements.
        let play = document.getElementById("playbutton");
        let pause = document.getElementById("pausebutton");

        // If the song is currently playing and the play button is active.
        if (this.isPlaying && !this.pauseButton) {

            play.classList.toggle('hidden');
            pause.classList.toggle('hidden');
            this.pauseButton = true;

        }

        // If this isn't playing and the pause button is active.
        if (!this.isPlaying && this.pauseButton) {

            play.classList.toggle('hidden');
            pause.classList.toggle('hidden');
            this.pauseButton = false;

        }

    }

}