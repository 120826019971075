<template>
  <div class="content">
    <Player :playlist="this.playlist" :cover="this.cover" :title="this.title" />
  </div>
</template>

<script>
import Player from "@/components/Player.vue";
export default {
  components: {
    Player,
  },
  data() {
    return {
      title: "Twilight Princess",
      cover: "twilight-princess.jpg",
      playlist: [
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-princess/Title-Theme.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-princess/Title-Theme.ogg",
          title: "Title Theme",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-princess/Ilias-Theme.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-princess/Ilias-Theme.ogg",
          title: "Ilia's Theme",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-princess/Ordon-Village.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-princess/Ordon-Village.ogg",
          title: "Ordon Village",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-princess/Faron-Woods.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-princess/Faron-Woods.ogg",
          title: "Faron Woods",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-princess/Lake-Hylia.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-princess/Lake-Hylia.ogg",
          title: "Lake Hylia",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-princess/Sacred-Grove.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-princess/Sacred-Grove.ogg",
          title: "Sacred Grove",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-princess/Hyrule-Field-on-Foot.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-princess/Hyrule-Field-on-Foot.ogg",
          title: "Hyrule Field on Foot",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-princess/Fishing-Hole.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-princess/Fishing-Hole.ogg",
          title: "Fishing Hole",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-princess/Hyrule-Castle-Market-East.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-princess/Hyrule-Castle-Market-East.ogg",
          title: "Hyrule Castle Market East",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-princess/Enter-Ilia.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-princess/Enter-Ilia.ogg",
          title: "Enter Ilia",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-princess/Midna-Suite.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-princess/Midna-Suite.ogg",
          title: "Midna Suite",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-princess/Get-Fused-Shadow.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-princess/Get-Fused-Shadow.ogg",
          title: "Get Fused Shadow",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-princess/Armogohma.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-princess/Armogohma.ogg",
          title: "Armogohma",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-princess/Hyrule-Field-on-Horse.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-princess/Hyrule-Field-on-Horse.ogg",
          title: "Hyrule Field on Horse",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-princess/Kakariko-Village.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-princess/Kakariko-Village.ogg",
          title: "Kakariko Village",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-princess/Back-from-the-Spring.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-princess/Back-from-the-Spring.ogg",
          title: "Back from the Spring",
        },
      ],
    };
  },
};
</script>
