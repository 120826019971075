<template>
  <div class="content">
    <Player :playlist="this.playlist" :cover="this.cover" :title="this.title" />
  </div>
</template>

<script>
import Player from "@/components/Player.vue";
export default {
  components: {
    Player,
  },
  data() {
    return {
      title: "Skyward Sword",
      cover: "skyward-sword.jpg",
      playlist: [
        {
          mp3:
            "https://static.zreoarchive.org/mp3/skyward-sword/E3-2010-Trailer.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/skyward-sword/E3-2010-Trailer.ogg",
          title: "E3 2010 Trailer",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/skyward-sword/E3-2010-Demo.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/skyward-sword/E3-2010-Demo.ogg",
          title: "E3 2010 Demo",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/skyward-sword/Sky-Temple.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/skyward-sword/Sky-Temple.ogg",
          title: "Sky Temple",
        },
      ],
    };
  },
};
</script>
