<template>
  <div class="content">
    <Player :playlist="this.playlist" :cover="this.cover" :title="this.title" />
  </div>
</template>

<script>
import Player from "@/components/Player.vue";
export default {
  components: {
    Player,
  },
  data() {
    return {
      title: "Spirit Tracks",
      cover: "spirit-tracks.jpg",
      playlist: [
        {
          mp3:
            "https://static.zreoarchive.org/mp3/spirit-tracks/Title-Theme.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/spirit-tracks/Title-Theme.ogg",
          title: "Title Theme",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/spirit-tracks/Aboda-Village-Outside.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/spirit-tracks/Aboda-Village-Outside.ogg",
          title: "Aboda Village Outside",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/spirit-tracks/Final-Train-Exam.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/spirit-tracks/Final-Train-Exam.ogg",
          title: "Final Train Exam",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/spirit-tracks/Castle-Town.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/spirit-tracks/Castle-Town.ogg",
          title: "Castle Town",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/spirit-tracks/In-the-Fields.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/spirit-tracks/In-the-Fields.ogg",
          title: "In the Fields",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/spirit-tracks/Tower-of-Spirits-Dungeon.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/spirit-tracks/Tower-of-Spirits-Dungeon.ogg",
          title: "Tower of Spirits Dungeon",
        },
        {
          mp3: "https://static.zreoarchive.org/mp3/spirit-tracks/Byrne.mp3",
          ogg: "https://static.zreoarchive.org/ogg/spirit-tracks/Byrne.ogg",
          title: "Byrne",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/spirit-tracks/Lokomos-Farewell.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/spirit-tracks/Lokomos-Farewell.ogg",
          title: "Lokomo's Farewell",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/spirit-tracks/Spirit-Tracks-Trailer.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/spirit-tracks/Spirit-Tracks-Trailer.ogg",
          title: "Spirit Tracks Trailer",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/spirit-tracks/Spirit-Tracks-Trailer-Soundscape.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/spirit-tracks/Spirit-Tracks-Trailer-Soundscape.ogg",
          title: "Spirit Tracks Trailer Soundscape",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/spirit-tracks/Spirit-Tracks-Trailer-Soundscape-Redux.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/spirit-tracks/Spirit-Tracks-Trailer-Soundscape-Redux.ogg",
          title: "Spirit Tracks Trailer Soundscape Redux",
        },
      ],
    };
  },
};
</script>
