<template>
  <div class="content">
    <Player :playlist="this.playlist" :cover="this.cover" :title="this.title" />
  </div>
</template>

<script>
import Player from "@/components/Player.vue";
export default {
  components: {
    Player,
  },
  data() {
    return {
      title: "Battlescapes",
      cover: "battlescapes.jpg",
      playlist: [
        {
          mp3:
            "https://static.zreoarchive.org/mp3/battlescapes/The-Legend.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/battlescapes/The-Legend.ogg",
          title: "The Legend",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/battlescapes/Princess-Zelda-Demo.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/battlescapes/Princess-Zelda-Demo.ogg",
          title: "Princess Zelda Demo",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/battlescapes/Ganondorf-Demo.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/battlescapes/Ganondorf-Demo.ogg",
          title: "Ganondorf Demo",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/battlescapes/Ganondorf-Soliloquy.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/battlescapes/Ganondorf-Soliloquy.ogg",
          title: "Ganondorf Soliloquy",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/battlescapes/Which-Side-Shall-Prevail.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/battlescapes/Which-Side-Shall-Prevail.ogg",
          title: "Which Side Shall Prevail",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/battlescapes/2007-Concept-Demo.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/battlescapes/2007-Concept-Demo.ogg",
          title: "2007 Concept Demo",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/battlescapes/Triforce-Sound-Test.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/battlescapes/Triforce-Sound-Test.ogg",
          title: "Triforce Sound Test",
        },
      ],
    };
  },
};
</script>
