<template>
  <div class="content">
    <Player :playlist="this.playlist" :cover="this.cover" :title="this.title" />
  </div>
</template>

<script>
import Player from "@/components/Player.vue";
export default {
  components: {
    Player,
  },
  data() {
    return {
      title: "Ocarina Of Time",
      cover: "ocarina-of-time.jpg",
      playlist: [
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Title-Theme.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Title-Theme.ogg",
          title: "Title Theme",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Enter-Ganondorf.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Enter-Ganondorf.ogg",
          title: "Enter Ganondorf",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Deku-Tree.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Deku-Tree.ogg",
          title: "Deku Tree",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Fairy-Flying.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Fairy-Flying.ogg",
          title: "Fairy Flying",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/House.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/House.ogg",
          title: "House",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Kokiri-Forest.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Kokiri-Forest.ogg",
          title: "Kokiri Forest",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Open-Treasure-Chest.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Open-Treasure-Chest.ogg",
          title: "Open Treasure Chest",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Item-Catch.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Item-Catch.ogg",
          title: "Item Catch",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Small-Item-Catch.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Small-Item-Catch.ogg",
          title: "Small Item Catch",
        },
        {
          mp3: "https://static.zreoarchive.org/mp3/ocarina-of-time/Shop.mp3",
          ogg: "https://static.zreoarchive.org/ogg/ocarina-of-time/Shop.ogg",
          title: "Shop",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Battle.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Battle.ogg",
          title: "Battle",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Inside-the-Deku-Tree.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Inside-the-Deku-Tree.ogg",
          title: "Inside the Deku Tree",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Boss-Battle.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Boss-Battle.ogg",
          title: "Boss Battle",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Boss-Clear.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Boss-Clear.ogg",
          title: "Boss Clear",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Heart-Container-Get.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Heart-Container-Get.ogg",
          title: "Heart Container Get",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Legend-of-Hyrule.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Legend-of-Hyrule.ogg",
          title: "Legend of Hyrule",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Spiritual-Stone-Get.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Spiritual-Stone-Get.ogg",
          title: "Spiritual Stone Get",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Fairy-Ocarina-Get.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Fairy-Ocarina-Get.ogg",
          title: "Fairy Ocarina Get",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Hyrule-Field-Main-Theme.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Hyrule-Field-Main-Theme.ogg",
          title: "Hyrule Field Main Theme",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Kaepora-Gaeboras-Theme.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Kaepora-Gaeboras-Theme.ogg",
          title: "Kaepora Gaeboras Theme",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Market.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Market.ogg",
          title: "Market",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Shooting-Gallery.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Shooting-Gallery.ogg",
          title: "Shooting Gallery",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Hyrule-Castle-Courtyard.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Hyrule-Castle-Courtyard.ogg",
          title: "Hyrule Castle Courtyard",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Enter-Zelda.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Enter-Zelda.ogg",
          title: "Enter Zelda",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Ocarina-Zeldas-Lullaby.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Ocarina-Zeldas-Lullaby.ogg",
          title: "Ocarina Zelda's Lullaby",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Zeldas-Theme.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Zeldas-Theme.ogg",
          title: "Zelda's Theme",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Ocarina-Eponas-Song.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Ocarina-Eponas-Song.ogg",
          title: "Ocarina Epona's Song",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Lon-Lon-Ranch.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Lon-Lon-Ranch.ogg",
          title: "Lon Lon Ranch",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Mini-Game.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Mini-Game.ogg",
          title: "Mini Game",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Kakariko-Village.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Kakariko-Village.ogg",
          title: "Kakariko Village",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Ocarina-Suns-Song.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Ocarina-Suns-Song.ogg",
          title: "Ocarina Sun's Song",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Hyrule-Field-Morning-Theme.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Hyrule-Field-Morning-Theme.ogg",
          title: "Hyrule Field Morning Theme",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Goron-City.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Goron-City.ogg",
          title: "Goron City",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Ocarina-Sarias-Song.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Ocarina-Sarias-Song.ogg",
          title: "Ocarina Saria's Song",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Lost-Woods.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Lost-Woods.ogg",
          title: "Lost Woods",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Dodongos-Cavern.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Dodongos-Cavern.ogg",
          title: "Dodongos Cavern",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Middle-Boss-Battle.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Middle-Boss-Battle.ogg",
          title: "Middle Boss Battle",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Dinosaur-Boss-Battle.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Dinosaur-Boss-Battle.ogg",
          title: "Dinosaur Boss Battle",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Zoras-Domain.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Zoras-Domain.ogg",
          title: "Zora's Domain",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Great-Fairys-Fountain.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Great-Fairys-Fountain.ogg",
          title: "Great Fairy's Fountain",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Potion-Shop.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Potion-Shop.ogg",
          title: "Potion Shop",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Inside-Jabu-Jabus-Belly.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Inside-Jabu-Jabus-Belly.ogg",
          title: "Inside Jabu Jabu's Belly",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Ocarina-Song-of-Time.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Ocarina-Song-of-Time.ogg",
          title: "Ocarina Song of Time",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Temple-of-Time.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Temple-of-Time.ogg",
          title: "Temple of Time",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Door-of-Time.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Door-of-Time.ogg",
          title: "Door of Time",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Master-Sword.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Master-Sword.ogg",
          title: "Master Sword",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Ganondorfs-Theme.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Ganondorfs-Theme.ogg",
          title: "Ganondorf's Theme",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Chamber-of-Sages.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Chamber-of-Sages.ogg",
          title: "Chamber of Sages",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Medal-Get-Fanfare.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Medal-Get-Fanfare.ogg",
          title: "Medal Get Fanfare",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Sheiks-Theme.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Sheiks-Theme.ogg",
          title: "Sheik's Theme",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Horse-Race.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Horse-Race.ogg",
          title: "Horse Race",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Horse-Race-Goal.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Horse-Race-Goal.ogg",
          title: "Horse Race Goal",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Ingos-Theme.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Ingos-Theme.ogg",
          title: "Ingo's Theme",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Escape-from-Lon-Lon-Ranch.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Escape-from-Lon-Lon-Ranch.ogg",
          title: "Escape from Lon Lon Ranch",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Kakariko-Village-Orchestra-Version.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Kakariko-Village-Orchestra-Version.ogg",
          title: "Kakariko Village Orchestra Version",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Ocarina-Song-of-Storms.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Ocarina-Song-of-Storms.ogg",
          title: "Ocarina Song of Storms",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Windmill-Hut.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Windmill-Hut.ogg",
          title: "Windmill Hut",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Minuet-of-Forest.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Minuet-of-Forest.ogg",
          title: "Minuet of Forest",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Forest-Temple.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Forest-Temple.ogg",
          title: "Forest Temple",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Bolero-of-Fire.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Bolero-of-Fire.ogg",
          title: "Bolero of Fire",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Fire-Temple.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Fire-Temple.ogg",
          title: "Fire Temple",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Ice-Cavern.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Ice-Cavern.ogg",
          title: "Ice Cavern",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Serenade-of-Water.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Serenade-of-Water.ogg",
          title: "Serenade of Water",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Water-Temple.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Water-Temple.ogg",
          title: "Water Temple",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Nocturne-of-Shadow.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Nocturne-of-Shadow.ogg",
          title: "Nocturne of Shadow",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Prelude-of-Light.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Prelude-of-Light.ogg",
          title: "Prelude of Light",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Shadow-Temple.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Shadow-Temple.ogg",
          title: "Shadow Temple",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Gerudo-Valley.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Gerudo-Valley.ogg",
          title: "Gerudo Valley",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Spirit-Temple.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Spirit-Temple.ogg",
          title: "Spirit Temple",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Requiem-of-Spirit.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Requiem-of-Spirit.ogg",
          title: "Requiem of Spirit",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Kotake-and-Koumes-Theme.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Kotake-and-Koumes-Theme.ogg",
          title: "Kotake and Koume's Theme",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Meet-Zelda-Again.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Meet-Zelda-Again.ogg",
          title: "Meet Zelda Again",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Game-Over.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Game-Over.ogg",
          title: "Game Over",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Ganons-Castle-Bridge.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Ganons-Castle-Bridge.ogg",
          title: "Ganon's Castle Bridge",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Ganons-Castle-Under-Ground.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Ganons-Castle-Under-Ground.ogg",
          title: "Ganon's Castle Under Ground",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Inside-Ganons-Castle.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Inside-Ganons-Castle.ogg",
          title: "Inside Ganon's Castle",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Ganondorf-Battle.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Ganondorf-Battle.ogg",
          title: "Ganondorf Battle",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Escape-from-Ganons-Castle.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Escape-from-Ganons-Castle.ogg",
          title: "Escape from Ganon's Castle",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Last-Battle.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Last-Battle.ogg",
          title: "Last Battle",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Seal-of-Six-Sages.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Seal-of-Six-Sages.ogg",
          title: "Seal of Six Sages",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/Ocarina-of-Time.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/Ocarina-of-Time.ogg",
          title: "Ocarina of Time",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/ocarina-of-time/End-Credits.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/ocarina-of-time/End-Credits.ogg",
          title: "End Credits",
        },
      ],
    };
  },
};
</script>
