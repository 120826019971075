<template>
  <div class="content">
    <Player :playlist="this.playlist" :cover="this.cover" :title="this.title" />
  </div>
</template>

<script>
import Player from "@/components/Player.vue";
export default {
  components: {
    Player,
  },
  data() {
    return {
      title: "Majora's Mask",
      cover: "majoras-mask.jpg",
      playlist: [
        {
          mp3:
            "https://static.zreoarchive.org/mp3/majoras-mask/Title-Demo.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/majoras-mask/Title-Demo.ogg",
          title: "Title Demo",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/majoras-mask/Opening-Demo.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/majoras-mask/Opening-Demo.ogg",
          title: "Opening Demo",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/majoras-mask/Clock-Tower.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/majoras-mask/Clock-Tower.ogg",
          title: "Clock Tower",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/majoras-mask/Clock-Town-Day-1.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/majoras-mask/Clock-Town-Day-1.ogg",
          title: "Clock Town Day 1",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/majoras-mask/Mayors-House-Council-Room.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/majoras-mask/Mayors-House-Council-Room.ogg",
          title: "Mayor's House Council Room",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/majoras-mask/Milk-Bar.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/majoras-mask/Milk-Bar.ogg",
          title: "Milk Bar",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/majoras-mask/Clock-Town-Day-2.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/majoras-mask/Clock-Town-Day-2.ogg",
          title: "Clock Town Day 2",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/majoras-mask/Clock-Town-Day-3.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/majoras-mask/Clock-Town-Day-3.ogg",
          title: "Clock Town Day 3",
        },
        {
          mp3: "https://static.zreoarchive.org/mp3/majoras-mask/Dancer.mp3",
          ogg: "https://static.zreoarchive.org/ogg/majoras-mask/Dancer.ogg",
          title: "Dancer",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/majoras-mask/The-Final-Hour.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/majoras-mask/The-Final-Hour.ogg",
          title: "The Final Hour",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/majoras-mask/Astral-Observatory.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/majoras-mask/Astral-Observatory.ogg",
          title: "Astral Observatory",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/majoras-mask/Song-of-Healing.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/majoras-mask/Song-of-Healing.ogg",
          title: "Song of Healing",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/majoras-mask/Goron-Race.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/majoras-mask/Goron-Race.ogg",
          title: "Goron Race",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/majoras-mask/Get-Mask.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/majoras-mask/Get-Mask.ogg",
          title: "Get Mask",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/majoras-mask/Termina-Field.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/majoras-mask/Termina-Field.ogg",
          title: "Termina Field",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/majoras-mask/Marsh-Land.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/majoras-mask/Marsh-Land.ogg",
          title: "Marsh Land",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/majoras-mask/Jungle-Cruise.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/majoras-mask/Jungle-Cruise.ogg",
          title: "Jungle Cruise",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/majoras-mask/Deku-Nuts-Palace.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/majoras-mask/Deku-Nuts-Palace.ogg",
          title: "Deku Nut's Palace",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/majoras-mask/Middle-Boss-Battle.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/majoras-mask/Middle-Boss-Battle.ogg",
          title: "Middle Boss Battle",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/majoras-mask/Breman-Mask.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/majoras-mask/Breman-Mask.ogg",
          title: "Breman Mask",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/majoras-mask/Boss-Battle.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/majoras-mask/Boss-Battle.ogg",
          title: "Boss Battle",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/majoras-mask/Great-Bay-Coast.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/majoras-mask/Great-Bay-Coast.ogg",
          title: "Great Bay Coast",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/majoras-mask/Pirates-Fortress.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/majoras-mask/Pirates-Fortress.ogg",
          title: "Pirates' Fortress",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/majoras-mask/New-Wave-Bossa-Nova.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/majoras-mask/New-Wave-Bossa-Nova.ogg",
          title: "New Wave Bossa Nova",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/majoras-mask/Ikana-Valley.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/majoras-mask/Ikana-Valley.ogg",
          title: "Ikana Valley",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/majoras-mask/Ikanas-Ancient-Castle.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/majoras-mask/Ikanas-Ancient-Castle.ogg",
          title: "Ikana's Ancient Castle",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/majoras-mask/Elegy-of-Emptiness.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/majoras-mask/Elegy-of-Emptiness.ogg",
          title: "Elegy of Emptiness",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/majoras-mask/Stone-Tower-Temple.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/majoras-mask/Stone-Tower-Temple.ogg",
          title: "Stone Tower Temple",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/majoras-mask/Calling-the-Four-Giants.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/majoras-mask/Calling-the-Four-Giants.ogg",
          title: "Calling the Four Giants",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/majoras-mask/Tatl-and-Tael-Reunite.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/majoras-mask/Tatl-and-Tael-Reunite.ogg",
          title: "Tatl and Tael Reunite",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/majoras-mask/Clock-Town-Day-1-Redux.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/majoras-mask/Clock-Town-Day-1-Redux.ogg",
          title: "Clock Town Day 1 Redux",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/majoras-mask/Mayors-House-Council-Room-Redux.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/majoras-mask/Mayors-House-Council-Room-Redux.ogg",
          title: "Mayor's House Council Room Redux",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/majoras-mask/Clock-Town-Day-2-Redux.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/majoras-mask/Clock-Town-Day-2-Redux.ogg",
          title: "Clock Town Day 2 Redux",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/majoras-mask/Pirates-Fortress-Redux.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/majoras-mask/Pirates-Fortress-Redux.ogg",
          title: "Pirates' Fortress Redux",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/majoras-mask/Ikana-Valley-Soundscape.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/majoras-mask/Ikana-Valley-Soundscape.ogg",
          title: "Ikana Valley Soundscape",
        },
      ],
    };
  },
};
</script>
