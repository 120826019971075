<template>
  <div class="content">
    <Player :playlist="this.playlist" :cover="this.cover" :title="this.title" />
  </div>
</template>

<script>
import Player from "@/components/Player.vue";
export default {
  components: {
    Player,
  },
  data() {
    return {
      title: "Link's Awakening",
      cover: "links-awakening.jpg",
      playlist: [
        {
          mp3:
            "https://static.zreoarchive.org/mp3/links-awakening/Mabe-Village.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/links-awakening/Mabe-Village.ogg",
          title: "Mabe Village",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/links-awakening/Mysterious-Woods.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/links-awakening/Mysterious-Woods.ogg",
          title: "Mysterious Woods",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/links-awakening/Overworld.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/links-awakening/Overworld.ogg",
          title: "Overworld",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/links-awakening/Tal-Tal-Mountain-Range.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/links-awakening/Tal-Tal-Mountain-Range.ogg",
          title: "Tal Tal Mountain Range",
        },
      ],
    };
  },
};
</script>
