<template>
  <div id="playlist-wrapper">
    <h1>{{ title }}</h1>
    <img alt="Cover Art" :src="getCover(cover)" />
    <div id="player">
      <div class="song">
        <span id="song-title"></span>
      </div>
      <span id="current-time">0:00</span>
      <span id="total-time">0:00</span>
      <div class="buffered">
        <span id="buffered-amount"></span>
      </div>
      <div class="progress">
        <input id="progress-amount" type="range" max="100" value="0" />
      </div>

      <b-icon
        v-on:click="prevSong()"
        icon="skip-backward-fill"
        class="button"
        font-scale="2.5"
      />
      <b-icon
        v-on:click="playSong()"
        id="playbutton"
        icon="play-fill"
        class="button"
        font-scale="2.5"
      />
      <b-icon
        v-on:click="pauseSong()"
        id="pausebutton"
        class="hidden button"
        icon="pause-fill"
        font-scale="2.5"
      />
      <b-icon
        v-on:click="nextSong()"
        icon="skip-forward-fill"
        class="button"
        font-scale="2.5"
      />
      <div class="volume">
        <input attrib="volume" id="volume-amount" type="range" max="100" value="50">
      </div>
    </div>
    <div id="playlist">
      <ol>
        <div>
          <li
            v-for="(s, i) in playlist"
            :key="i"
            v-on:click="playSong(i)"
            :id="'playitem_' + i"
          >
            <b-icon icon="play" font-scale="2"></b-icon>{{ i + 1 }}.
            {{ s.title }}
          </li>
        </div>
      </ol>
    </div>
  </div>
</template>

<style scoped>
#player {
  padding-top: 20px;
}

.song {
  height: 30px;
  font-size: 14px;
  margin-bottom: 10px;
}

#current-time {
  float: left;
  margin-left: 20px;
  margin-top: -10px;
  width: 50px;
}

#total-time {
  float: right;
  margin-right: 20px;
  margin-top: -10px;
  width: 50px;
}

.buffered {
  margin: 0 auto;
  height: 5px;
  position: relative;
  background: #555;
  width: 400px;
  border-radius: 5px;
}

#buffered-amount {
  display: block;
  height: 100%;
  background-color: #2da860;
  width: 0;
  border-radius: 5px;
}

.progress {
  margin: 0 auto;
  margin-top: -5px;
  height: 5px;
  position: relative;
  background-color: transparent;
  width: 400px;
  border-radius: 5px;
}

#volume-amount {

  border: 1px solid white;
  border-radius: 5px;
}

#progress-amount {
  width: 400px;
  height: 100%;
  /*background-color: transparent;*/
}

/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="range"] {
    overflow: hidden;
    -webkit-appearance: none;
    background-color: transparent;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    height: 10px;
    -webkit-appearance: none;
    color: #d4ce46;
    margin-top: -1px;
  }

  input[type="range"]::-webkit-slider-thumb {
    width: 5px;
    -webkit-appearance: none;
    height: 20px;
    cursor: ew-resize;
    background: #d4ce46;
    box-shadow: -200px 0 0 200px #d4ce46;
  }
}

/** FF*/
input[type="range"]::-moz-range-progress {
  background-color: #d4ce46;
  height: 100%;
}
input[type="range"]::-moz-range-track {
  background-color: transparent;
}
/* IE*/
input[type="range"]::-ms-fill-lower {
  background-color: #d4ce46;
}
input[type="range"]::-ms-fill-upper {
  background-color: transparent;
}

#player .button {
  margin-top: 10px;
  margin-right: 5px;
  margin-left: 5px;
}

#player .hidden {
  overflow: hidden;
  font-size: 0;
  margin: 0;
  opacity: 0;
  padding: 0;
  max-height: 0;
  height: 0px;
  width: 0px;
}

#playlist-wrapper {
  border-bottom: 1px solid #455a64;
  border-radius: 5px;
  margin-top: 52px;
  margin-bottom: 52px;
  padding-top: 10px;
  display: inline-block;
  overflow: none;
  width: 550px;
  background-color: #263238;
  color: #cfd8dc;
  box-shadow: 0 0 50px #263238;
  margin-left: 250px;
}

@media screen and (max-width: 768px) {
  #playlist-wrapper {
    border-bottom: 1px solid #455a64;
    border-radius: 0px;
    margin-top: 52px;
    margin-bottom: 52px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
    display: inline-block;
    overflow: none;
    width: 550px;
    background-color: #263238;
    color: #cfd8dc;
  }
}

#playlist-wrapper img {
  width: 500px;
  border-radius: 5px;
  box-shadow: 0 0 10px #455a64;
}

#playlist {
  padding-top: 20px;
}

#playlist li {
  display: block;
  background-color: #37474f;
  text-decoration: none;
  text-align: left;
  width: 470px;
  padding: 10px;
  border: 1px solid #455a64;
}

#playlist li:hover {
  background: none;
  color: #2da860;
  text-decoration: none;
}
</style>

<script>
import player from "../assets/player.js";
export default {
  name: "Player",
  props: {
    title: String,
    playlist: Array,
    cover: String,
  },
  mounted() {
    player.init(this.title);
  },
  methods: {
    stopSong() {
      player.stopMusic();
    },
    playSong(song) {
      player.addMusic(this.playlist);
      player.playSong(song);
    },
    nextSong() {
      player.nextSong();
    },
    prevSong() {
      player.prevSong();
    },
    pauseSong() {
      player.pauseSong();
    },
    getCover(img) {
      return require("@/assets/covers/" + img);
    },
  },
};
</script>