<template>
  <div class="content">
    <Player :playlist="this.playlist" :cover="this.cover" :title="this.title" />
  </div>
</template>

<script>
import Player from "@/components/Player.vue";
export default {
  components: {
    Player,
  },
  data() {
    return {
      title: "Twilight Symphony",
      cover: "twilight-symphony.jpg",
      playlist: [
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/A-Door-to-the-Past.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/A-Door-to-the-Past.ogg",
          title: "A Door to the Past",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Arbiters-Grounds.mp3", 
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Arbiters-Grounds.ogg",
          title: "Arbiter's Grounds",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Back-from-the-Spring.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Back-from-the-Spring.ogg",
          title: "Back from the Spring",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Blood-Spirit-and-Hatred.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Blood-Spirit-and-Hatred.ogg",
          title: "Blood, Spirit, and Hatred",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Colins-Kidnapping-and-the-Battle-of-Eldin-Bridge.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Colins-Kidnapping-and-the-Battle-of-Eldin-Bridge.ogg",
          title: "Colin's Kidnapping and the Battle of Eldin Bridge",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Dark-Lord-Ganondorf.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Dark-Lord-Ganondorf.ogg",
          title: "Dark Lord Ganondorf",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Death-Mountain.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Death-Mountain.ogg",
          title: "Death Mountain",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/End-Credits-Part-I.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/End-Credits-Part-I.ogg",
          title: "End Credits, Part I",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/End-Credits-Part-II.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/End-Credits-Part-II.ogg",
          title: "End Credits, Part II",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Faron-Woods.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Faron-Woods.ogg",
          title: "Faron Woods",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Fishing-Hole.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Fishing-Hole.ogg",
          title: "Fishing Hole",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Ganondorfs-Exile.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Ganondorfs-Exile.ogg",
          title: "Ganondorf's Exile",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Gerudo-Desert.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Gerudo-Desert.ogg",
          title: "Gerudo Desert",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Goron-Guardian-Dangoro.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Goron-Guardian-Dangoro.ogg",
          title: "Goron Guardian Dangoro",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Hidden-Village.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Hidden-Village.ogg",
          title: "Hidden Village",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/History-of-Light-and-Shadow.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/History-of-Light-and-Shadow.ogg",
          title: "History of Light and Shadow",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Hyrule-Castle-Overtaken.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Hyrule-Castle-Overtaken.ogg",
          title: "Hyrule Castle Overtaken",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Hyrule-Castle-Town.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Hyrule-Castle-Town.ogg",
          title: "Hyrule Castle Town",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Hyrule-Castle.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Hyrule-Castle.ogg",
          title: "Hyrule Castle",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Hyrule-Field-at-Night.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Hyrule-Field-at-Night.ogg",
          title: "Hyrule Field at Night",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Hyrule-Field.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Hyrule-Field.ogg",
          title: "Hyrule Field",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Hyrulean-Odd-Jobs.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Hyrulean-Odd-Jobs.ogg",
          title: "Hyrulean Odd Jobs",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Ilia.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Ilia.ogg",
          title: "Ilia",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Intermezzo.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Intermezzo.ogg",
          title: "Intermezzo",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Into-the-Twilight.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Into-the-Twilight.ogg",
          title: "Into the Twilight",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Kakariko-Village.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Kakariko-Village.ogg",
          title: "Kakariko Village",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/King-of-Shadows.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/King-of-Shadows.ogg",
          title: "King of Shadows",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Lake-Hylia.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Lake-Hylia.ogg",
          title: "Lake Hylia",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Light-Spirits-Message.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Light-Spirits-Message.ogg",
          title: "Light Spirit's Message",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Midnas-Lament.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Midnas-Lament.ogg",
          title: "Midna's Lament",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Midna.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Midna.ogg",
          title: "Midna",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Ooccoo-Fellow-Adventurer.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Ooccoo-Fellow-Adventurer.ogg",
          title: "Ooccoo, Fellow Adventurer",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Ook-and-the-Boomerang.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Ook-and-the-Boomerang.ogg",
          title: "Ook and the Boomerang",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Ordon-Ranch.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Ordon-Ranch.ogg",
          title: "Ordon Ranch",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Ordon-Village.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Ordon-Village.ogg",
          title: "Ordon Village",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Princess-Zelda.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Princess-Zelda.ogg",
          title: "Princess Zelda",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Ralis-Prince-of-the-Zoras.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Ralis-Prince-of-the-Zoras.ogg",
          title: "Ralis, Prince of the Zoras",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Overture.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Overture.ogg",
          title: "Overture",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Rutelas-Wish.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Rutelas-Wish.ogg",
          title: "Rutela's Wish",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Sacred-Grove.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Sacred-Grove.ogg",
          title: "Sacred Grove",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Snowboarding.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Snowboarding.ogg",
          title: "Snowboarding",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Snowpeak-Ruins.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Snowpeak-Ruins.ogg",
          title: "Snowpeak Ruins",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/The-Twilight-Realm.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/The-Twilight-Realm.ogg",
          title: "The Twilight Realm",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Throne-of-the-Demon-Thief.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Throne-of-the-Demon-Thief.ogg",
          title: "Throne of the Demon Thief",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Twilit-Arachnid-Armogohma.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Twilit-Arachnid-Armogohma.ogg",
          title: "Twilit Arachnid Armogohma",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Twilit-Dragon-Argorok.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Twilit-Dragon-Argorok.ogg",
          title: "Twilit Dragon Argorok",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Twilit-Fossil-Stallord.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Twilit-Fossil-Stallord.ogg",
          title: "Twilit Fossil Stallord",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Twilit-Ice-Mass-Blizzeta.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Twilit-Ice-Mass-Blizzeta.ogg",
          title: "Twilit Ice Mass Blizzeta",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Twilit-Parasite-Diababa.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Twilit-Parasite-Diababa.ogg",
          title: "Twilit Parasite Diababa",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Ursurper-King-Zant.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Ursurper-King-Zant.ogg",
          title: "Ursurper King Zant",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/twilight-symphony/Waltz-of-the-Bugs.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/twilight-symphony/Waltz-of-the-Bugs.ogg",
          title: "Waltz of the Bugs",
        },
      ],
    };
  },
};
</script>
