<template>
  <div class="content">
    <Player :playlist="this.playlist" :cover="this.cover" :title="this.title" />
  </div>
</template>

<script>
import Player from "@/components/Player.vue";
export default {
  components: {
    Player,
  },
  data() {
    return {
      title: "The Legend of Zelda",
      cover: "legend-of-zelda.jpg",
      playlist: [
        {
          title: "Title Theme",
          ogg:
            "https://static.zreoarchive.org/ogg/legend-of-zelda/Title-Theme.ogg",
          mp3:
            "https://static.zreoarchive.org/mp3/legend-of-zelda/Title-Theme.mp3",
        },
        {
          title: "Death Mountain",
          ogg:
            "https://static.zreoarchive.org/ogg/legend-of-zelda/Death-Mountain.ogg",
          mp3:
            "https://static.zreoarchive.org/mp3/legend-of-zelda/Death-Mountain.mp3",
        },
        {
          title: "End Credits",
          ogg:
            "https://static.zreoarchive.org/ogg/legend-of-zelda/End-Credits.ogg",
          mp3:
            "https://static.zreoarchive.org/mp3/legend-of-zelda/End-Credits.mp3",
        },
      ],
    };
  },
};
</script>
