<template>
  <div class="content">
    <Player :playlist="this.playlist" :cover="this.cover" :title="this.title" />
  </div>
</template>

<script>
import Player from "@/components/Player.vue";
export default {
  components: {
    Player,
  },
  data() {
    return {
      title: "The Adventure of Link",
      cover: "adventure-of-link.jpg",
      playlist: [
        {
          mp3:
            "https://static.zreoarchive.org/mp3/adventure-of-link/Title-Theme.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/adventure-of-link/Title-Theme.ogg",
          title: "Title Theme",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/adventure-of-link/Town-Theme.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/adventure-of-link/Town-Theme.ogg",
          title: "Town Theme",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/adventure-of-link/Overworld.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/adventure-of-link/Overworld.ogg",
          title: "Overworld",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/adventure-of-link/Battle.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/adventure-of-link/Battle.ogg",
          title: "Battle",
        },
      ],
    };
  },
};
</script>
