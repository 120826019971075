<template>
  <div class="content">
    <Player :playlist="this.playlist" :cover="this.cover" :title="this.title" />
  </div>
</template>

<script>
import Player from "@/components/Player.vue";
export default {
  components: {
    Player,
  },
  data() {
    return {
      title: "The Minish Cap",
      cover: "minish-cap.jpg",
      playlist: [
        {
          mp3: "https://static.zreoarchive.org/mp3/minish-cap/Cave.mp3",
          ogg: "https://static.zreoarchive.org/ogg/minish-cap/Cave.ogg",
          title: "Cave",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/minish-cap/Cloud-Tops.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/minish-cap/Cloud-Tops.ogg",
          title: "Cloud Tops",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/minish-cap/Cucco-Chase.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/minish-cap/Cucco-Chase.ogg",
          title: "Cucco Chase",
        },
        {
          mp3: "https://static.zreoarchive.org/mp3/minish-cap/Miniboss.mp3",
          ogg: "https://static.zreoarchive.org/ogg/minish-cap/Miniboss.ogg",
          title: "Miniboss",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/minish-cap/Palace-of-Winds.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/minish-cap/Palace-of-Winds.ogg",
          title: "Palace of Winds",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/minish-cap/Royal-Crypt.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/minish-cap/Royal-Crypt.ogg",
          title: "Royal Crypt",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/minish-cap/Mt.-Crenel.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/minish-cap/Mt.-Crenel.ogg",
          title: "Mt. Crenel",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/minish-cap/Temple-of-Droplets.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/minish-cap/Temple-of-Droplets.ogg",
          title: "Temple of Droplets",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/minish-cap/Royal-Valley.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/minish-cap/Royal-Valley.ogg",
          title: "Royal Valley",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/minish-cap/Royal-Valley-Soundscape.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/minish-cap/Royal-Valley-Soundscape.ogg",
          title: "Royal Valley Soundscape",
        },
      ],
    };
  },
};
</script>
