<template>
  <div class="content">
    <Player :playlist="this.playlist" :cover="this.cover" :title="this.title" />
  </div>
</template>

<script>
import Player from "@/components/Player.vue";
export default {
  components: {
    Player,
  },
  data() {
    return {
      title: "Oracle of Seasons",
      cover: "oracle-of-seasons.jpg",
      playlist: [
        {
          mp3:
            "https://static.zreoarchive.org/mp3/oracle-of-seasons/Title-Theme.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/oracle-of-seasons/Title-Theme.ogg",
          title: "Title Theme",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/oracle-of-seasons/Syrup-the-Witch.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/oracle-of-seasons/Syrup-the-Witch.ogg",
          title: "Syrup the Witch",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/oracle-of-seasons/Dancing-Dragon-Dungeon.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/oracle-of-seasons/Dancing-Dragon-Dungeon.ogg",
          title: "Dancing Dragon Dungeon",
        },
        {
          mp3:
            "https://static.zreoarchive.org/mp3/oracle-of-seasons/Sunken-City.mp3",
          ogg:
            "https://static.zreoarchive.org/ogg/oracle-of-seasons/Sunken-City.ogg",
          title: "Sunken City",
        },
      ],
    };
  },
};
</script>
